<template>
  <div class="bg">
    <div class="flash">
      <ul>
        <li v-show="model == 0">
          <a href="#"
            ><img
              width="926"
              height="153"
              alt=""
              src="../assets/pic/banner1.png"
          /></a>
        </li>
        <li v-show="model == 1">
          <a href="#"
            ><img
              width="833"
              height="153"
              alt=""
              src="../assets/pic/banner2.png"
          /></a>
        </li>
        <li v-show="model == 2">
          <a href="#"
            ><img
              width="833"
              height="153"
              alt=""
              src="../assets/pic/banner3.png"
          /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
export default {
  data() {
    return {
      count: 1,
      model: 0,
      time:null
    };
  },
  methods: {
  
  },
  created() {
  },
  mounted() {
    this.time = setInterval(() => {
      this.model = this.count%3
      this.count++
      console.log(this.model)
    }, 7700);
  
  },
  beforeDestroy () {
      clearInterval(this.time)
  }
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  min-width: 1200px;
  height: 950px;
  background: url("../assets/pic/bg1.gif");
  background-size: 100% 100%;
}
.flash {
  width: 926px;
  height: 153px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  top: 300px;
}

.flash li {
  position: absolute;
  left: 0;
  top: 0;
  width: 926px;
  height: 153px;
  text-align: center;
}

</style>
